// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-error-js": () => import("./../../../src/pages/contact-error.js" /* webpackChunkName: "component---src-pages-contact-error-js" */),
  "component---src-pages-contact-submitted-js": () => import("./../../../src/pages/contact-submitted.js" /* webpackChunkName: "component---src-pages-contact-submitted-js" */),
  "component---src-pages-create-instance-js": () => import("./../../../src/pages/create-instance.js" /* webpackChunkName: "component---src-pages-create-instance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instance-created-js": () => import("./../../../src/pages/instance-created.js" /* webpackChunkName: "component---src-pages-instance-created-js" */),
  "component---src-pages-instance-error-js": () => import("./../../../src/pages/instance-error.js" /* webpackChunkName: "component---src-pages-instance-error-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */)
}

